<template>
    <div class="about row">
        <vxe-grid ref="xGrid" v-bind="gridOptions">
            <template #option_btn="{ row }">
                <div class="btn-group" role="group" aria-label="Basic mixed styles example">
                    <button type="button" @click="edit(row)" class="btn btn-success">
                        <i class="fa-solid fa-pen-to-square"></i>
                    </button>
                    <button type="button" class="btn btn-warning d-none">Middle</button>
                    <button type="button" @click="removeOne(row)" class="btn btn-danger">
                        <i class="fa-solid fa-trash-can"></i>
                    </button>
                </div>
            </template>
            <template #status_switch="{ row }">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" :data-id="row.id" :checked="row.status"
                        @change="change($event, row)" />
                </div>
            </template>
            <template #toolbar_buttons>
                <vxe-button @click="add" status="success">新增</vxe-button>
                <vxe-button @click="chooseStore">指派上门</vxe-button>
            </template>
        </vxe-grid>
        <vxe-modal v-model="showStore" :title="'选择商家'" width="545" min-width="300" min-height="300" :loading="loadding"
            :resize="true" destroy-on-close>
            <template #default>
                <vxe-form :data="storeData" title-align="right" @submit="toStore">
                    <vxe-form-item title="Basic information" title-align="left" :span="24"
                        :title-prefix="{ icon: 'vxe-icon-comment' }"></vxe-form-item>

                    <vxe-form-item field="sid" title="选择商家" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-select @change="changeType" v-model="data.sid" :options="storelist" transfer
                                placeholder="请选择商家">
                            </vxe-select>
                        </template>
                    </vxe-form-item>

                    <vxe-form-item align="center" title-align="left" :span="24">
                        <template #default>
                            <vxe-button type="submit">提交</vxe-button>
                        </template>
                    </vxe-form-item>
                </vxe-form>
            </template>
        </vxe-modal>
        <vxe-modal v-model="showEdit" :title="selectRow ? '编辑&保存' : '新增&保存'" width="545" min-width="300" min-height="300"
            :loading="loadding" :resize="true" destroy-on-close @close="reset">
            <template #default>
                <vxe-form :data="formData" :rules="formRules" title-align="right" @submit="submitEvent">
                    <template v-if="!selectRow">

                        <vxe-form-item title="Basic information" title-align="left" :span="24"
                            :title-prefix="{ icon: 'vxe-icon-comment' }"></vxe-form-item>

                        <vxe-form-item field="oid" title="订单号" :title-suffix="{
                            content: '订单号非必填，如不填写则系统自动生成',
                            icon: 'vxe-icon-info-circle-fill',
                        }" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.oid" placeholder="请输入订单号"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="type" title="订单类型" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-select @change="changeType" v-model="data.type" :options="ordertypes" transfer
                                    placeholder="请选择订单类型">
                                </vxe-select>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="custom_name" title="用户名" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.custom_name" placeholder="请输入用户名"></vxe-input>
                            </template>
                        </vxe-form-item>

                        <vxe-form-item title="Other information" title-align="left" :title-width="200" :span="24"
                            :title-prefix="{
                                message: '请填写必填项',
                                icon: 'vxe-icon-info-circle-fill',
                            }"></vxe-form-item>
                        <vxe-form-item field="content" title="订单内容" :span="24" :item-render="{}" :title-suffix="{
                            message: '订单内容必填',
                            icon: 'vxe-icon-question-circle-fill',
                        }">
                            <template #default="{ data }">
                                <vxe-textarea v-model="data.content" :autosize="{ minRows: 2, maxRows: 4 }">
                                </vxe-textarea>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="extra_info" title="订单备注" :span="24" :item-render="{}" :title-suffix="{
                            message: '当发生改价时，要在备注里写清楚修改的内容',
                            icon: 'vxe-icon-question-circle-fill',
                        }">
                            <template #default="{ data }">
                                <vxe-textarea v-model="data.extra_info" :autosize="{ minRows: 2, maxRows: 4 }">
                                </vxe-textarea>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="price" title="金额" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.price" placeholder="请输入金额" type="float"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item align="center" title-align="left" :span="24">
                            <template #default>
                                <vxe-button type="submit">提交</vxe-button>
                                <vxe-button @click="reset">重置</vxe-button>
                            </template>
                        </vxe-form-item>
                    </template>
                    <template v-else>
                        <vxe-form-item title="Basic information" title-align="left" :span="24"
                            :title-prefix="{ icon: 'vxe-icon-comment' }"></vxe-form-item>
                        <vxe-form-item field="oid" title="订单号" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.oid" disabled></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="type" title="订单类型" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-select @change="changeType" v-model="data.type" disabled :options="orderTypesAll"
                                    transfer placeholder="请选择订单类型">

                                </vxe-select>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="custom_name" title="用户名" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.custom_name" disabled placeholder="请输入用户名"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="store_name" title="店铺" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.store_name" disabled placeholder=""></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="worker_name" title="技师" :span="24" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.worker_name" disabled placeholder=""></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item title="Other information" title-align="left" :title-width="200" :span="24"
                            :title-prefix="{
                                message: '请填写必填项',
                                icon: 'vxe-icon-info-circle-fill',
                            }"></vxe-form-item>
                        <vxe-form-item field="content" title="订单内容" :span="24" :item-render="{}" :title-suffix="{
                            message: '订单内容必填',
                            icon: 'vxe-icon-question-circle-fill',
                        }">
                            <template #default="{ data }">
                                <vxe-textarea v-model="data.content" disabled :autosize="{ minRows: 2, maxRows: 4 }">
                                </vxe-textarea>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="extra_info" title="订单备注" :span="24" :item-render="{}" :title-suffix="{
                            message: '当发生改价时，要在备注里写清楚修改的内容',
                            icon: 'vxe-icon-question-circle-fill',
                        }">
                            <template #default="{ data }">
                                <vxe-textarea v-model="data.extra_info" :autosize="{ minRows: 2, maxRows: 4 }">
                                </vxe-textarea>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="price" title="金额" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.price" placeholder="请输入金额" type="float"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item align="center" title-align="left" :span="24">
                            <template #default>
                                <vxe-button type="submit">提交</vxe-button>
                                <vxe-button @click="reset">重置</vxe-button>
                            </template>
                        </vxe-form-item>
                    </template>




                </vxe-form>
            </template>
        </vxe-modal>
    </div>
</template>
<script>
import {
    ordersAll,
    ordersList,
    ordersAdd,
    getAllWorker,
    getAllSotre,
    ordersUpdate,
    ordersDel,
    kfToStore,
    getDoorStore
} from "@/requests/api";
import { VXETable } from "vxe-table";
import XEUtils from "xe-utils";
import { ref } from 'vue'
const xGrid = ref()
const all_type = [
    { label: '到店', value: 0 },
    {
        label: '上门', value: 1
    },
    {
        label: '邮寄', value: 2
    },
    {
        label: '全部', value: -1
    }
]
const all_status = [
    { label: '未指派', value: 1 },
    {
        label: '已指派', value: 2
    },
    {
        label: '维修中', value: 3
    },
    {
        label: '已完成', value: 4
    },
    {
        label: '全部', value: -1
    }
]
const orderTypes = [
    '到店订单',
    '上门订单',
    '邮寄订单'
]
const orderObj = [
    { label: '到店订单', value: 0 },
    { label: '上门订单', value: 1 },
    { label: '邮寄订单', value: 2 }
]
const statusTypes = ['待服务', '服务中', '待支付', '已完成', '已取消', '待退款', '已退款', '未退款']

const statusObj = [
    { label: '全部', value: -1 },
    { label: '待服务', value: 0 },
    {
        label: '服务中', value: 1
    },
    {
        label: '待支付', value: 2
    },
    {
        label: '已完成', value: 3
    },
    {
        label: '已取消', value: 4
    },
    {
        label: '待退款', value: 5
    },
    {
        label: '已退款', value: 6
    },
    {
        label: '未退款', value: 7
    }
]
const repair_data = ['未知', '未指派', '已指派', '维修中', '已完成']
export default {
    name: "",
    mounted() {
        console.log(this.$refs.xGrid);
    },
    created() {

    },
    data() {
        return {
            stores: [],
            workers: [],
            showStore: false,
            selectRow: true,
            orderTypesAll: orderObj,
            ordertypes: [{ label: '上门订单', value: 1 }],
            showEdit: false,
            loadding: false,
            storeData: {
                sid: ""
            },
            formData: {
                type: 1,
                custom_name: "",
                content: "",
                extra_info: "",
                price: 0,
            },
            typelist: ["get", "post", "put", "delete"],
            formRules: {
                name: [
                    { required: true, message: "请输入名称" },
                    { min: 2, max: 6, message: "长度在 2 到 6 个字符" },
                ],
            },
            gridOptions: {
                border: true,
                showHeaderOverflow: true,
                showOverflow: true,
                keepSource: true,
                id: "menu_list_table",
                height: 800,
                rowConfig: {
                    keyField: "id",
                    isHover: true,
                },
                columnConfig: {
                    resizable: true,
                },
                customConfig: {
                    storage: true,
                    // checkMethod({ column }) {
                    //     if (["create_time", "update_time"].includes(column.field)) {
                    //         return false;
                    //     }
                    //     return true;
                    // },
                },
                printConfig: {
                    // columns: [
                    //     { field: "name" },
                    //     { field: "path" },
                    //     { field: "link" },
                    //     { field: "pid" },
                    //     { field: "icon" },
                    //     { field: "status" },
                    //     { field: "order" },
                    //     { field: "desc" },
                    // ],
                },
                sortConfig: {
                    trigger: "cell",
                    remote: true,
                },
                filterConfig: {
                    remote: true,
                },
                pagerConfig: {
                    pageSize: 10,
                    pageSizes: [5, 10, 15, 20, 50, 100, 200, 500, 1000],
                },
                formConfig: {
                    titleWidth: 100,
                    titleAlign: "center",
                    items: [
                        {
                            field: "oid",
                            title: "订单号",
                            titlePrefix: {
                                message: "订单号不是id",
                                icon: "vxe-icon-question-circle-fill",
                            },
                            itemRender: {
                                name: "$input",
                                props: { placeholder: "请输入订单号" },
                            },
                        },
                        {
                            field: "custom_name",
                            title: "用户名",
                            itemRender: {
                                name: "$input",
                                props: { placeholder: "请输入用户名" },
                            },
                        },
                        {
                            field: "store_name",
                            title: "店铺名",
                            itemRender: {
                                name: "$input",
                                props: { placeholder: "请输入店铺名" },
                            },
                        },
                        {
                            field: "worker_name",
                            title: "技师名",
                            itemRender: {
                                name: "$input",
                                props: { placeholder: "请输入技师名" },
                            },
                        },
                        {
                            field: "start_time",
                            title: "开始日期",
                            folding: true,
                            itemRender: {
                                name: "$input",
                                props: { placeholder: "请输入开始日期", type: 'datetime' },
                            },
                        },
                        {
                            field: "end_time",
                            title: "结束日期",
                            folding: true,
                            itemRender: {
                                name: "$input",
                                props: { placeholder: "请输入结束日期", type: 'datetime' },
                            },
                        },
                        {
                            field: "type",
                            title: "订单类型",
                            folding: true,
                            itemRender: {
                                name: "$select",
                                options: all_type,
                                defaultValue: -1
                            },
                        },
                        {
                            field: "repair_state",
                            title: "指派状态",
                            folding: true,
                            itemRender: {
                                name: "$select",
                                options: all_status,
                                defaultValue: -1
                            },
                        },
                        {
                            field: "status",
                            title: "订单状态",
                            folding: true,
                            itemRender: {
                                name: "$select",
                                options: statusObj,
                                defaultValue: -1
                            },
                        },
                        {
                            align: "center",
                            collapseNode: true,
                            span: 24,
                            itemRender: {
                                name: "$buttons",
                                children: [
                                    {
                                        props: {
                                            type: "submit",
                                            content: "搜索",
                                            status: "primary",
                                        },
                                    },
                                    { props: { type: "reset", content: "清空" } },
                                ],
                            },
                        },
                    ],
                },
                toolbarConfig: {
                    slots: {
                        buttons: "toolbar_buttons",
                    },
                    refresh: true,
                    import: false,
                    export: false,
                    print: true,
                    zoom: true,
                    custom: true,
                },
                proxyConfig: {
                    seq: true, // 启用动态序号代理，每一页的序号会根据当前页数变化
                    sort: true, // 启用排序代理，当点击排序时会自动触发 query 行为
                    filter: true, // 启用筛选代理，当点击筛选时会自动触发 query 行为
                    form: true, // 启用表单代理，当点击表单提交按钮时会自动触发 reload 行为
                    // 对应响应结果 { result: [], page: { total: 100 } }
                    props: {
                        result: "data", // 配置响应结果列表字段
                        total: "total", // 配置响应结果总页数字段
                    },
                    // 只接收Promise，具体实现自由发挥
                    ajax: {
                        // 当点击工具栏查询按钮或者手动提交指令 query或reload 时会被触发
                        query: ({ page, sorts, filters, form }) => {
                            const queryParams = Object.assign({}, form);
                            // 处理排序条件
                            const firstSort = sorts[0];
                            if (firstSort) {
                                queryParams.sort = firstSort.field;
                                queryParams.order = firstSort.order;
                            }
                            // 处理筛选条件
                            filters.forEach(({ field, values }) => {
                                queryParams[field] = values.join(",");
                            });
                            queryParams.page = page.currentPage;
                            queryParams.limit = page.pageSize;
                            return ordersList(queryParams);
                        },
                        queryAll: ({ filters, form }) => {
                            const queryParam = Object.assign({}, form);
                            filters.forEach(({ field, values }) => {
                                queryParam[field] = values.join(",");
                            });
                            return ordersAll(queryParam);
                        },
                        // 当点击工具栏删除按钮或者手动提交指令 delete 时会被触发
                        delete: ({ body }) => {
                            console.log(body);

                            return;
                        },
                        // 当点击工具栏保存按钮或者手动提交指令 save 时会被触发
                        save: ({ body }) => {
                            console.log(body);
                            return;
                        },
                        edit: ({ id }) => {
                            console.log(id);
                        },
                    },
                },
                columns: [
                    { type: "checkbox", title: "ID", width: 86 },
                    {
                        field: "oid",
                        sortable: false,
                        title: "订单号",
                    },
                    {
                        field: "type",
                        title: "类型",
                        sortable: false,
                        formatter({ cellValue }) {
                            return orderTypes[cellValue];
                        },
                    },
                    {
                        field: "custom_name",
                        title: "用户名",
                        width: 90,
                        sortable: false,
                        titlePrefix: {
                            useHTML: true,
                            content: "到店订单为用户的微信昵称，其他类型订单为导入时输入的用户名",
                        },
                    },
                    {
                        field: "store_name",
                        title: "指派店铺",
                        sortable: false,
                        titlePrefix: {
                            useHTML: true,
                            content: "到店订单为用户下单的店铺名，其他类型订单为手动指派的店铺名",
                        },
                    },
                    {
                        field: "worker_name",
                        title: "指派技师",
                        sortable: false,
                        titlePrefix: {
                            useHTML: true,
                            content: "到店订单为用户下单的技师名，其他类型订单为手动指派的技师名",
                        },
                    },
                    {
                        field: "content",
                        title: "订单详情",
                        sortable: false
                    },
                    {
                        field: "extra_info",
                        title: "订单备注",
                        sortable: false
                    },
                    {
                        field: "price",
                        title: "金额",
                        sortable: false
                    },

                    {
                        field: "create_time",
                        title: "创建时间",
                        sortable: false,
                        formatter({ cellValue }) {
                            return XEUtils.toDateString(cellValue, "yyyy-MM-dd HH:ss:mm");
                        },
                    },
                    {
                        field: "update_time",
                        title: "更新时间",
                        sortable: false,
                        formatter({ cellValue }) {
                            return XEUtils.toDateString(cellValue, "yyyy-MM-dd HH:ss:mm");
                        },
                    },
                    {
                        field: "status",
                        title: "订单状态",
                        sortable: false,
                        formatter({ cellValue }) {
                            return statusTypes[cellValue];
                        },
                    },
                    {
                        field: "repair_state",
                        title: "指派状态",
                        visible: true,
                        formatter({ cellValue }) {
                            return repair_data[cellValue];
                        },
                    },
                    // {
                    //     field: "id",
                    //     title: "操作",
                    //     type: "html",
                    //     slots: {
                    //         default: "option_btn",
                    //     },
                    // },

                ],
                importConfig: {
                    remote: true,
                    types: ["xlsx"],
                    modes: ["insert"],
                    // 自定义服务端导入
                    importMethod({ file }) {
                        const $grid = xGrid.value;
                        const formBody = new FormData();
                        formBody.append("file", file);
                        return fetch({})
                            .then((response) => response.json())
                            .then((data) => {
                                VXETable.modal.message({
                                    content: `成功导入 ${data.result.insertRows} 条记录！`,
                                    status: "success",
                                });
                                // 导入完成，刷新表格
                                $grid.commitProxy("query");
                            })
                            .catch(() => {
                                VXETable.modal.message({
                                    content: "导入失败，请检查数据是否正确！",
                                    status: "error",
                                });
                            });
                    },
                },
                exportConfig: {
                    remote: true,
                    // types: ["xls"],
                    // modes: ["current", "selected", "all"],
                    // 自定义服务端导出
                    // exportMethod({ options }) {
                    //     const $grid = xGrid.value;
                    //     const proxyInfo = $grid.getProxyInfo();
                    //     // 传给服务端的参数
                    //     const body = {
                    //         filename: options.filename,
                    //         sheetName: options.sheetName,
                    //         isHeader: options.isHeader,
                    //         original: options.original,
                    //         mode: options.mode,
                    //         pager: proxyInfo ? proxyInfo.pager : null,
                    //         ids:
                    //             options.mode === "selected"
                    //                 ? options.data.map((item) => item.id)
                    //                 : [],
                    //         fields: options.columns.map((column) => {
                    //             return {
                    //                 field: column.field,
                    //                 title: column.title,
                    //             };
                    //         }),
                    //     };
                    //     // 开始服务端导出
                    //     return fetch({ body })
                    //         .then((response) => response.json())
                    //         .then((data) => {
                    //             if (data.id) {
                    //                 VXETable.modal.message({
                    //                     content: "导出成功，开始下载",
                    //                     status: "success",
                    //                 });
                    //                 // 读取路径，请求文件
                    //                 fetch({}).then((response) => {
                    //                     response.blob().then((blob) => {
                    //                         // 开始下载
                    //                         VXETable.saveFile({
                    //                             filename: "导出数据",
                    //                             type: "xlsx",
                    //                             content: blob,
                    //                         });
                    //                     });
                    //                 });
                    //             }
                    //         })
                    //         .catch(() => {
                    //             VXETable.modal.message({
                    //                 content: "导出失败！",
                    //                 status: "error",
                    //             });
                    //         });
                    // },
                },
                checkboxConfig: {
                    labelField: "id",
                    reserve: true,
                    highlight: true,
                    range: true,
                },
            },
        };
    },
    methods: {
        changeType(e) {
            if (e.value == "") {
                return
            }
            getAllSotre({ type: e.value }).then((res) => {
                this.stores = res
            })
            this.formData.sid = ""
            this.formData.wid = ""
        },
        changeStore(e) {
            if (e.value == "") {
                return
            }
            getAllWorker({ type: this.formData.type, sid: e.value }).then((res) => {
                this.workers = res
            })
        },
        add() {
            this.selectRow = false;
            this.showEdit = true;
        },
        edit(row) {
            this.selectRow = true;
            this.formData = row;
            this.showEdit = true;
            delete this.formData.create_time;
            delete this.formData.update_time;
        },
        removeOne(row) {
            VXETable.modal
                .confirm({
                    content: "删除后无法恢复！确定要删除？",
                })
                .then((res) => {
                    if (res == "confirm") {
                        this.notifyDelete([row.id]);
                        this.$refs.xGrid.remove(row);
                    } else {
                        VXETable.modal.message({
                            status: "info",
                            content: "操作取消",
                            top: 80,
                        });
                    }
                });
        },
        removeMany() {
            VXETable.modal
                .confirm({
                    content: "删除后无法恢复！确定要删除多条记录？",
                })
                .then((res) => {
                    if (res == "confirm") {
                        this.notifyDelete(
                            this.$refs.xGrid.getCheckboxRecords().map((x) => {
                                return x.id;
                            })
                        );
                        this.$refs.xGrid.removeCheckboxRow();
                    } else {
                        VXETable.modal.message({
                            status: "info",
                            content: "操作取消",
                            top: 80,
                        });
                    }
                });
        },
        notifyDelete(ids) {
            ordersDel({
                id: ids,
            }).then(() => {
                VXETable.modal.message({
                    status: "success",
                    content: "操作成功！",
                    top: 80,
                });
            });
        },
        change(e, row) {
            console.log(e, row);
            // menuUpdate({
            //   id: row.id,
            //   status: e.target.checked ? 1 : 0,
            // })
            //   .then((res) => {
            //     console.log(res);
            //   })
            //   .catch((err) => {
            //     console.log(err);
            //   });
        },
        reset() {
            var default_Data = {

                type: 1,
                custom_name: "",
                content: "",
                extra_info: "",
                price: 0,
            };
            this.formData = default_Data;
        },
        submitEvent() {
            if (this.selectRow) {
                ordersUpdate(this.formData).then(() => {
                    this.$refs.xGrid.reloadRow(this.formData);
                    this.showEdit = false;
                    VXETable.modal.message({
                        content: `更新成功`,
                        status: "success",
                    });
                    this.$refs.xGrid.commitProxy('query')
                });
            } else {
                ordersAdd(this.formData)
                    .then((res) => {
                        console.log(res);
                        this.showEdit = false;
                        VXETable.modal.message({
                            content: `添加成功`,
                            status: "success",
                        });
                        this.$refs.xGrid.commitProxy('query')
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            this.reset();
        },
        toStore() {
            if (this.storeData.sid == "") {
                VXETable.modal.message({
                    content: `必须选择店铺`,
                    status: "error",
                });
                return
            }
            var ids = this.$refs.xGrid.getCheckboxRecords().map((x) => {
                return x.id;
            })
            kfToStore({ id: ids, sid: this.storeData.sid }).then(
                () => {
                    VXETable.modal.message({
                        content: `指派成功`,
                        status: "success",
                    });
                    this.showStore = false
                }
            )
        },
        chooseStore() {
            var verify = true
            var ids = this.$refs.xGrid.getCheckboxRecords().map((x) => {
                if (x.type != 1 || x.repair_state != 0) {
                    verify = false
                }
                return x.id
            })
            if (verify && ids.length != 0) {
                getDoorStore().then((res) => {
                    this.showStore = true
                    this.storelist = res.map((x) => {
                        return { label: x.name, value: x.id }
                    })
                })
            } else {
                VXETable.modal.message({
                    content: `必须选择尚未指派的上门订单`,
                    status: "error",
                });
            }
        },
        visibleMethod() { },
    },
};
</script>
<style>
/* .vxe-table--header {
          background-color: green;
          color: #fff;
        } */
</style>
  